<template>
  <div>wait to build</div>
</template>

<script>
export default {
  name: "pcListArticlePage"
}
</script>

<style scoped>

</style>